import React from 'react'
import './App.css'
import logo from "./logo.png"
import android from "./images/Andriod-Icon.png"
import desktop from "./images/Desktop-Icon.png"
import coin from "./images/Coin.png"
import {RiBook2Line} from "react-icons/ri"


function App() {
  return (
    <div className='main'>
      <div className='subdiv'>
      <div className='logomain' >  
      <img className='logo' src={logo}/>
      </div>
      <center>
      <div className='txtmain' >
        <h1 className='txt'>Explore All of Your IDs</h1>
        <h1 className='txt1' >At One Place</h1>
        <span className='txt2'>Experience the best pf all betting and gaming on TEXCH app<br></br>Happy punching</span>
      </div>
      </center>
       <a className='atag' href='http://texchapp.com' target="_blank"> 
       <div className='link1'>
      <div className='webb'>
          <img height='60%' width="60%" src={desktop}/>
      </div>
        <div className='webb1'>
         <h2 className='web'>WEBAPP</h2>
         <span className='web1'>Support windows,mac,ipad,iphone</span>
        </div>
      </div>
      </a>
      <a className='atag' href='https://texch-apk.s3.amazonaws.com/Texch.apk' target="_blank">
      <div className='link2'>
      <div className='webb4'>
          <img height='60%' width="60%" src={android}/>
      </div>
        <div className='webb1'>
         <h2 className='web3'>DOWNLOAD APP</h2>
         <span className='web4'>For android device</span>
        </div>
      </div>
      </a>
      <div className='guide'>
        <div className='g1'><RiBook2Line color='#ffe700'></RiBook2Line><span style={{marginLeft:'3px'}}>  How to install guide</span></div><br/>
      <span className='g2' style={{color:"#ffe700"}}>For support click here</span>
      </div>
      </div>
      <div className='subdiv1'>
        <img className='coinnn' src={coin}/>
      </div>
    </div>
  )
}

export default App